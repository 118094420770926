export const environment = {
  production: false,
  staging: true,
  apiUrl: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '769098a1-eca6-422d-b15f-20d142f4e554',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '8c29db56-2cd0-4fa4-b9d6-84c2f15749c6',
  paypal_clientID: 'AZ5-jh2EW9Mm6Lyrns15sQfUfWqg3jehR2sEv3vnVs6xWakUawUsopPlkRB8iyo6BoJvN8Flh_hr8eXu',
  //? External links
  externalLinks: {
    deimos: 'https://elecnor-deimos.com',
    esa: 'https://www.esa.int',
    vito: 'https://remotesensing.vito.be',
    nova: 'https://www.novasbe.unl.pt',
    terraDue: 'https://www.terradue.com/portal',
    dhi: 'https://www.dhi-gras.com',
    vandersat: 'https://vandersat.com',
    bdb: 'https://www.bdb.be',
    soilessentials: 'https://www.soilessentials.com',
    st: 'https://www.stcorp.no',
    efi: 'https://efi.int/',
    certh: 'https://www.iti.gr/iti/index.html',
    forest_design: 'https://www.forestdesign.ro/',
    junta_de_castilla_y_leon: 'https://www.jcyl.es/',
    metsakeskus:'https://www.metsakeskus.fi/',
    support: 'https://service4eo.atlassian.net/servicedesk/customer/portal/10',
    twitter:'https://twitter.com/NextLand_EO',
    linkedin:'https://www.linkedin.com/company/nextland-eo/',
    requestAreaUrl:'https://service4eo.atlassian.net/servicedesk/customer/portal/10/group/49/create/150',
    youtube:'https://www.youtube.com/channel/UCScyB2KfSrD7FAPOzLbViKQ'
  },

  cms: {
    url: 'https://cms4eo.staging.services4eo.com',
    api: 'https://cms4eo.staging.services4eo.com/api',
    website: 'nxtl',
  },
  downloadAllHelp:{
    url:'https://en.wikipedia.org/wiki/Comparison_of_download_managers'
  },
  geoportalUrl: '',
  outputFilterPattern: '',
  analyticsID: 'G-MZKNYD0T9M'
};
