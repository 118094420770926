import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IOrderJob, JobOutput, Layer, 
  OrderInputs, OrdersServices, 
  OrderStatus, Service } from "@core";
import { format } from "date-fns";
import { saveAs } from 'file-saver';
import { Subject, timer } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { environment } from "../../../../../../../environments/environment";
import { animate, style, transition, trigger } from '@angular/animations';
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp, faExternalLinkAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-order-item-details',
  templateUrl: './order-item-details.component.html',
  styleUrls: ['./order-item-details.component.scss'],
  animations: [
    trigger('Details', [
      transition(':enter', [
        style({
          height: '0',
          opacity: '0',
        }),
        animate(
          '400ms ease-in-out',
          style({
            height: '*',
            opacity: '100',
          }),
        ),
      ]),
      transition(':leave', [
        animate(
          '400ms ease-in-out',
          style({
            height: '0',
            opacity: '0',
          }),
        ),
      ]),
    ]),
  ],
})
export class OrderItemDetailsComponent implements OnInit, OnDestroy {
  @Input() service!: Service;
  @Input() orderId!: number
  @Input() orderStatus!: OrderStatus
  @Input() inputs: OrderInputs[];
  @Input() isMetadataLoading: boolean;
  @Input() isSubscription: boolean;
  @Output() viewMetadata = new EventEmitter<string>();

  objectKeys = Object.keys;
  orderJobs: IOrderJob[] = [];
  source = timer(0, 60000);
  componentDestroyed$: Subject<boolean> = new Subject();
  formatDate = format;
  isLoading: boolean = true;
  isShowHelp: boolean = false;
  env = environment;
  isShowDetails: boolean = false;
  map:Map<string,boolean>;
  isShowingMap: boolean = false;
  filterInputs:OrderInputs[];

  clickedMetadataOutputIndex: number;
  clickedMetadataJobIndex: number;
  clickedMetadataLayerIndex: number;
  clickedMetadataLayerKey: string;

  outputFilter = environment.outputFilterPattern;

  chevronDown: IconDefinition = faChevronDown;
  chevronUp: IconDefinition = faChevronUp;
  faExternalLinkAlt: IconDefinition = faExternalLinkAlt;
  faInfoCircle = faInfoCircle;
  faSpinner: IconDefinition = faSpinner;

  filterArray = [
    'current_SURCOUF/data-store:eastward_sea_water_velocity',
    'oilspill/data-store:Count',
    'wind/data-store:eastward_wind',
    'Tide/data-store:eastward_sea_water_velocity_due_to_barotropic_ocean_tide',
    'wind/data-store:northward_wind',
    'current_SURCOUF/data-store:northward_sea_water_velocity'
  ];

  constructor(
    private ordersService: OrdersServices    
  ) {
    this.map = new Map();
  }

  get orderHasAllOutputs() {
    return this.orderJobs?.every((job) => job.outputs.length > 0);
  }

  ngOnInit(): void {
    this.filterInputs = this.inputs.filter(k => k.identifier !== 'acceptedTermsAndConditions');
    if (this.orderStatus === OrderStatus.FINISHED) {
      this.isLoading = true;
      this.ordersService.getOrderJobs(this.orderId).subscribe({
        next: jobs => {
          this.orderJobs = jobs;
          this.ordersService.getOrderOutputs(this.orderId).subscribe({
            next: outputs => {

              outputs.forEach((output,outputIdx) => {
                const relatedJob = this.orderJobs.find((job,jobIdx) => job.id === output.jobId);
                if (relatedJob) {
                    this.objectKeys(output.layers).forEach((layerkey)=>{
                      output.layers[layerkey].forEach((layer: any, layerIdx: number) => {
                        const mapValues = `${relatedJob.id}-${outputIdx}-${layerkey}-${layerIdx}`;
                        this.map.set(mapValues,false);
                      });
                    })
                  if (relatedJob.outputs) {
                    relatedJob.outputs.push(output);
                  } else {
                    relatedJob.outputs = [];
                    relatedJob.outputs.push(output);
                  }
                  if(relatedJob.outputs[0].productIdentifier === 'SITE_RISK_ASSESSMENT__SRA____METOCEAN_20230323164826_dissemination_service'){
                    this.filterArray.forEach(key => {
                      const res:any = output.layers;
                      delete res[key];
                    });
                  }
                }
              });
              this.isLoading = false
            },
            error: () => this.isLoading = false
          });
  
        },
        error: () => this.isLoading = false
      });

    } else if (this.orderStatus !== OrderStatus.ERROR) {
      this.source.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
        this.isLoading = true;
        this.ordersService.getOrderJobs(this.orderId).subscribe({
          next: jobs => {
            this.orderJobs = jobs;
              this.ordersService.getOrderOutputs(this.orderId).subscribe({
                next: outputs => {
                  outputs.forEach((output, outputIdx) => {
                    const relatedJob = this.orderJobs.find((job,jobIdx) => job.id === output.jobId);
                    if (relatedJob) {
                      this.objectKeys(output.layers).forEach((layerkey)=>{
                        output.layers[layerkey].forEach((layer: any, layerIdx: number) => {
                          const mapValues = `${relatedJob.id}-${outputIdx}-${layerkey}-${layerIdx}`;
                          this.map.set(mapValues,false);
                        });
                      })
                      if (relatedJob.outputs) {
                        relatedJob.outputs.push(output);
                      } else {
                        relatedJob.outputs = [];
                        relatedJob.outputs.push(output);
                      }
                    }
                  });
                  this.isLoading = false
                },
                error: () => this.isLoading = false
              });
          },
          error: () => this.isLoading = false
        });
      });
    }
   
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  serializeDate(date: string): string {
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
  }

  downloadAll(orderId: number) {
    this.ordersService.downloadAllOrderResults(orderId).pipe(take(1)).subscribe((blob) => {
      saveAs(blob, orderId + '_download.meta4')
    });
  }


  setMap(outputIdx:number,layerIdx:number,layerKey:string,jobId:number){
    const inputs = `${outputIdx}-${layerIdx}-${layerKey}-${jobId}`;
    this.map.set(inputs,!this.map.get(inputs));
  }
  
  getMap(outputIdx: number,layerIdx: number,layerKey: string,jobId: number){
   const inputs = `${outputIdx}-${layerIdx}-${layerKey}-${jobId}`;
   return this.map.get(inputs) ?? false;
  }

  specificOrder(url:string,timeStamp:string){
    const finalTimeStamp = timeStamp.replace('Z','');
    return url + finalTimeStamp;
  }

  hasWMSOutput(outputs: JobOutput[]) {
    let isWMSOutput = false;
    if (outputs) {
      outputs.forEach((output) => {
        this.objectKeys(output.layers).forEach((layerKey) => {
          output.layers[layerKey].forEach((l: Layer) => {
            isWMSOutput = (isWMSOutput || (l.geoServerURI !== null && l.wmsParams !== null ));
          });
        });
      });
    }
    return isWMSOutput;
  }

  isTimeSeries(output: JobOutput): boolean {
    return output.type !== undefined && 
      (output.type === 'data-dissemination-time-series' ||
      output.type === 'data-collection-netcdf-time-series');
  }

  onViewMetadata(layerInfo: Layer, outputIndex: number, jobIndex: number, layerIndex: number, layerKey: string) {
    let fileName: string;
    if (layerInfo.wmsParams && layerInfo.wmsParams.dataset) {
      fileName = layerInfo.wmsParams.dataset;
    } else {
      fileName = layerInfo.title;
    }
    this.clickedMetadataOutputIndex = outputIndex;
    this.clickedMetadataJobIndex = jobIndex;
    this.clickedMetadataLayerIndex = layerIndex;
    this.clickedMetadataLayerKey = layerKey;
    this.viewMetadata.emit(fileName);
  }

  get orderHasOutputs() {
    return this.orderJobs.some(job => job.outputs && job.outputs.length > 0);
  }

  isMetadataButtonClicked(outputIndex: number, jobIndex: number, layerIndex: number, layerKey: string): boolean {
    return outputIndex === this.clickedMetadataOutputIndex &&
      jobIndex === this.clickedMetadataJobIndex && 
      layerIndex === this.clickedMetadataLayerIndex &&
      layerKey === this.clickedMetadataLayerKey;
  }
  
  getFilteredOutputs(outputs: JobOutput[]): JobOutput[] {
    if (this.outputFilter && this.outputFilter !== '') {
      return outputs.filter(out => !out.productIdentifier.includes(this.outputFilter));
    } else {
      return outputs;
    }
  }

  redirectTo(serviceIdentifier: string, orderId: number) {
    const url = `${this.env.geoportalUrl}?service=${serviceIdentifier}&order=${orderId}`;
    window.open(url, '_blank');
  }
}
